import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import PrismicReact from 'prismic-reactjs';
import SimpleReactValidator from 'simple-react-validator';
import { Skeleton, Alert, Button, Input, Divider, Icon } from 'antd';
import { Elements } from 'react-stripe-elements';
import CheckoutForm from '../components/CheckoutForm';

import { lookup, login, registerUser, updateEck } from '../services/user';
import { createEnrolment } from '../services/enrolment';

const EMAIL_STEP   = 0;
const ACCOUNT_STEP = 1;
const PAYMENT_STEP = 2;
const CONFIRM_STEP = 3;

const STUDIO_KEYS = {
  'None': 0,
  'Free Trial': 1,
  'Full Subscription': 2
};

export default class Payment extends React.Component {
  recaptchaRef = null;

  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      stripe: null,
      loading: false,
      step: EMAIL_STEP,
      email: '',
      fname: '',
      lname: '',
      password: '',
      confirm: '',
      cardHolderName: ''
    };

    this.query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (!this.query.pid) {
      this.props.history.push('/');
    }

    this.state.email = this.query.email;
    if (this.state.email) {
      this.handleFirstStep();
    }

    if (props.prismicCtx) {
      this.fetchPage(props);
    }

    this.recaptchaRef = React.createRef();
  }

  componentWillReceiveProps(nextProps){
    this.fetchPage(nextProps);
  }

  fetchPage(props) {
    window.scrollTo(0, 0);
    this.setState({ doc: null });

    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getByUID('product', this.query.pid, 
        {'fetchLinks': 
          [
            'event.event_image',
            'event.event_title', 
            'event.event_description',
          ]
        }, (err, doc) => {
        if (doc) {
          let subtitle = null;
          let price = 0;
          let plan = null;
          let courseId = null;
          let studioSubscription = null;
          let studioAccess = null;
          let sendCoupon = false;
          let redirectPayment = false;

          if (doc.data.pricing && doc.data.pricing.length > 0) {
            const pricing = this.query.index ? doc.data.pricing[this.query.index] : doc.data.pricing[0];
            subtitle = PrismicReact.RichText.asText(pricing.price_description);
            price = pricing.price_amount;
            plan = PrismicReact.RichText.asText(pricing.stripe_plan_id);
            courseId = pricing.pricing_course_id ? pricing.pricing_course_id : doc.data.course_id;
            studioSubscription = pricing.studio_subscription;
            studioAccess = pricing.studio_access;
            sendCoupon = pricing.send_coupon === 'true' ? true : false;
            redirectPayment = pricing.redirect_payment === 'true' ? true : false;
          } else if (doc.data.events && doc.data.events.length > 0) {
            const event = this.query.index ? doc.data.events[this.query.index].event : doc.data.events[0].event;
            subtitle = PrismicReact.RichText.asText(event.data.event_title);
            //price = event.data.event_amount;
            //courseId = event.data.event_course_id;
            //studioSubscription = event.event_studio_subscription;
            //studioAccess = event.event_studio_access;
          }

          const product = {
            plan: plan,
            price: price,
            description: PrismicReact.RichText.asText(doc.data.title),
            event: subtitle,
            courseId: courseId,
            studioSubscription: STUDIO_KEYS[studioSubscription],
            studioAccess: studioAccess,
            sendCoupon: sendCoupon,
            redirectPayment: redirectPayment,
            tos: doc.data.terms_of_sale.url ? doc.data.terms_of_sale.url : '/terms-of-sales/'
          };

          this.setState({ doc, subtitle, product });
          document.title = `${PrismicReact.RichText.asText(doc.data.title)} | Blue Ocean Academy`;
        } else {
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.validator.hideMessages();
      this.setState({ loading: true, formError: null });
      switch (this.state.step) {
        case EMAIL_STEP:
          this.handleFirstStep();
          break;
        case ACCOUNT_STEP:
          this.handleSecondStep();
          break;
        case PAYMENT_STEP:
          this.handleThirdStep();
          break;
        default:
          console.log('Invalid step');
          this.setState({ loading: false });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleFirstStep = () => {
    lookup(this.state.email)
      .then(result => {
        this.setState({ loading: false, step: ACCOUNT_STEP, new: result.data.found === 0 });
      })
      .catch(err => console.log(err));
  }

  handleSecondStep = () => {
    if (this.state.new && this.state.password !== this.state.confirm) {
      this.setState({ loading: false, formError: 'Password fields do not match.' });
      return;
    }
    if (!this.validatePassword(this.state.password)) {
      this.setState({ loading: false, formError: 'Password needs to have atleast 8 characters and contain both letters and numbers.' });
      return;
    }

    if (this.state.new) {
      if (this.state.product.price === 0 || this.state.product.redirectPayment) {
        registerUser({
          email: this.state.email,
          password: this.state.password,
          first_name: this.state.fname,
          last_name: this.state.lname
        })
        .then(result => {
          this.account = result.data;
          this.handleFreeEnrolment();

          window.ga('send', 'event', {
            eventCategory: 'free_registration',
            eventAction: 'successful',
            eventLabel: this.account.mail
          });
        });
      } else {
        var user = {
          email: this.state.email,
          password: this.state.password,
          fname: this.state.fname,
          lname: this.state.lname
        };
        this.setState({ loading: false, step: PAYMENT_STEP, user });
      }
    } else {
      login({username: this.state.email, password: this.state.password})
        .then(result => {
          this.account = result.data;
          if (this.state.product.price === 0 || this.state.product.redirectPayment) {
            this.handleFreeEnrolment();
          } else {
            this.setState({ loading: false, step: PAYMENT_STEP, account: this.account });
          }
        })
        .catch(err => {
          this.setState({loading: false, formError: err.response.data.message});
        });
    }
  }

  handleFreeEnrolment = () => {
    const { doc, product } = this.state;
    window.ga('send', 'event', {
      eventCategory: `${PrismicReact.RichText.asText(doc.data.title)}`,
      eventAction: 'successful',
      eventLabel: this.account.mail
    });

    if (product.studioAccess) {
      updateEck(this.account, {
        field_studio_subscription: [{value: product.studioSubscription}],
        field_studio_access: [{value: product.studioAccess}],
        field_activation_validity: [{value: 0}],
        field_source: [{value: `${product.description}:${product.courseId}`}]
      });
    } else {
      updateEck(this.account, {
        field_source: [{value: `${product.description}:${product.courseId}`}]
      });
    }

    if (product.redirectPayment) {
      window.location.href = `https://pay.go1.com/?themed=true&lo=${this.state.product.courseId}&portal=${process.env.REACT_APP_GO1_INSTANCE_ID}&jwt=${this.account.jwt}&redirect=${encodeURIComponent(`https://learning.bogn.com/p/#/app/course-view/${this.state.product.courseId}/overview?instance=${process.env.REACT_APP_GO1_INSTANCE_ID}&enrolment=true`)}`;
      return;
    }

    var redirectLink = `https://${process.env.REACT_APP_GO1_INSTANCE_DOMAIN}/p/#/token?token=${this.account.uuid}`;
    if (this.state.product.courseId && this.state.product.courseId !== 1649241) {
      createEnrolment(this.account, this.state.product.courseId, null).then(result => {
        this.setState({ loading: false, step: CONFIRM_STEP, redirectLink });
      });
    } else {
      this.setState({ loading: false, step: CONFIRM_STEP, redirectLink });
    }
  };

  handleBack = () => {
    this.setState({ step: this.state.step - 1 });
  };

  validatePassword = (str) => {
    if (str.length >= 8 && str.search(/\d/) !== -1 && str.search(/[a-zA-Z]/) !== -1) {
      return true;
    }
    return false;
  };

  render() {
    this.validator.purgeFields();
    if (this.state.doc) {
      return (
        <React.Fragment>
          <div className="container">
            <div className="col-lg-6 col-md-8 px-0 px-md-5 mx-auto text-center" style={{marginTop: '100px', marginBottom:'80px'}}>
              <div className="col-md-10 mx-auto bg-light py-3 rounded shadow-lg" style={{marginTop: '150px'}}>
                <div className="m-auto payment-image rounded-circle" style={{backgroundImage: `url(${this.state.doc.data.card_image.url})`}}></div>
                <h5 className="font-weight-normal text-primary">{PrismicReact.RichText.asText(this.state.doc.data.title)}</h5>
                { this.state.subtitle && <h5 className="font-weight-bold text-primary">{this.state.subtitle}</h5> }
                { this.state.step < CONFIRM_STEP && (
                  <form onSubmit={this.handleSubmit} className="payment-form text-left pt-3">
                    { this.state.step >= EMAIL_STEP && (
                      <div className="form-group">
                        <Input size="large" placeholder="Email" disabled={this.state.step >= 1} value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
                        {this.validator.message('email', this.state.email, 'required|email')}
                      </div>
                    )}
                    { this.state.step >= ACCOUNT_STEP && this.state.new && (
                      <div className="row mt-3 no-gutters">
                        <Divider className="text-secondary mb-3 text-uppercase" style={{fontSize: '14px'}}>Complete Registration Details</Divider>
                        <div className="col-md-6 form-group pr-md-2">
                          <Input size="large" placeholder="First Name" disabled={this.state.step >= 2} value={this.state.fname} onChange={(e) => this.setState({fname: e.target.value})} />
                          {this.validator.message('first name', this.state.fname, 'required|alpha_space')}
                        </div>
                        <div className="col-md-6 form-group">
                          <Input size="large" placeholder="Last Name" disabled={this.state.step >= 2} value={this.state.lname} onChange={(e) => this.setState({lname: e.target.value})} />
                          {this.validator.message('last name', this.state.lname, 'required|alpha_space')}
                        </div>
                      </div>
                    )}
                    { this.state.step >= ACCOUNT_STEP && (
                      <div className="form-group">
                        <Input.Password size="large" placeholder="Password" disabled={this.state.step >= 2} value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} />
                        {this.validator.message('password', this.state.password, 'required')}
                      </div>
                    )}
                    { this.state.step >= ACCOUNT_STEP && this.state.new && (
                      <div className="form-group">
                        <Input.Password size="large" placeholder="Confirm Password" disabled={this.state.step >= 2} value={this.state.confirm} onChange={(e) => this.setState({confirm: e.target.value})} />
                        {this.validator.message('confirm password', this.state.confirm, 'required')}
                      </div>
                    )}
                    { this.state.step >= PAYMENT_STEP && (
                      <>
                        <Elements>
                          <CheckoutForm account={this.state.account} user={this.state.user} product={this.state.product} recaptchaRef={this.recaptchaRef} />
                        </Elements>
                      </>
                    )}
                    { this.state.step < PAYMENT_STEP && (
                      <Button role="button" className="btn-block" type="primary" onClick={this.handleSubmit} loading={this.state.loading}>Next</Button>
                    )}
                    { this.state.step !== EMAIL_STEP && <button type="button" className="btn btn-outline-primary btn-block" onClick={this.handleBack}>Back</button>}
                  </form>
                )}
                { this.state.step === CONFIRM_STEP && (
                  <div className="pb-5 px-3 mt-3">
                    <Icon type="check-circle" className="text-success" style={{fontSize: '40px'}} />
                    <Divider>Registration Successful!</Divider>
                    <a className="btn btn-primary text-white mt-4" href={this.state.redirectLink}>Enter Blue Ocean Academy™</a>
                  </div>
                )}
                { this.state.formError && (
                  <Alert
                    className="mt-3 text-left"
                    message="Uh oh!"
                    description={this.state.formError}
                    type="error"
                    showIcon
                  />
                )}
              </div>
              <img className="mt-4 col-md-3 col-6" src="/images/cc-icon.png" alt="Stripe Logo" />
            </div>
          </div>
        </React.Fragment>
      );
    }
    return <div className="container py-5"><Skeleton /></div>;
  }
}
import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { Divider, Icon } from 'antd';

export default class PaymentSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (this.query.uuid) {
      this.redirectLink = `https://${process.env.REACT_APP_GO1_INSTANCE_DOMAIN}/p/#/token?token=${this.query.uuid}`;
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div className="container">
        <div className="col-lg-6 col-md-8 px-0 px-md-5 mx-auto text-center" style={{marginTop: '100px', marginBottom:'80px'}}>
          <div className="col-md-10 mx-auto bg-light py-3 rounded shadow-lg" style={{marginTop: '150px'}}>
            <div className="pb-5 px-3 mt-3">
              <Icon type="check-circle" className="text-success mb-2" style={{fontSize: '40px'}} />
              <Divider>Registration Successful!</Divider>
              <a className="btn btn-primary text-white mt-4" href={this.redirectLink}>Enter Blue Ocean Academy™</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}